import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MenuPage = () => (
  <Layout>
    <SEO title="Menu Thai Village Cuisine" />
    <div className="menu-bg"></div>
    <div className="the-menu">
      <section>
        <div className="container menu">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2>Appetizer</h2>

              <h3>1. CHICKEN SATAY / BEEF SATAY <span>$10.95</span></h3>
              <p>Chicken or Beef skewered and marinated in coconut milk and Thai spices, charbroiled. Served with peanut curry sauce & cucumber salad.</p>
              <h3>2. TOD MUN <span>$10.95</span></h3>
              <p>Deep fried fish cakes marinated fish paste, green beans and curry paste. Served with cucumber salad, peanut.</p>
              <h3>3. ANGEL CHICKEN WINGS <span>$12.95</span></h3>
              <p>Crispy boneless chicken wings, stuffed with seasoned ground pork & prawns, silver noodles, garlic, and pepper. Served with cucumber salad.</p>
              <h3>4. GOLDEN SHRIMPS <span>$10.95</span></h3>
              <p>Deep fried golden Shrimps. Served with sweet 'n chili sauce.</p>
              <h3>5. SIAM LETTUCE WRAPS WITH TOFU <span>$9.95</span></h3>
              <p>Finely chopped Chicken stir-fried with sweet onion, bell pepper and mushroom in house marinade sauce, served with fresh iceberg lettuce. (WITH CHICKEN $9.95)</p>
              <h3>6. GOLDEN TOFU <span>$9.95</span></h3>
              <p>Deep-fried tofu served with our delicious peanut sauce.</p>
              <h3>7. FRIED EGG ROLLS <span>$8.95</span></h3>
              <p>Egg rolls served with sweet'n chili sauce.</p>
              <h3>8. ISAAN SAUSAGE <span>$9.95</span></h3>
              <p><h3>9. ROTI & GREEN OR YELLOW CURRY <span>$8.95</span></h3></p>
              <p>Indian-style flat bread served with veggie green or yellow curry and coconut milk dipping sauce.</p>
              <h3>10. GOLDEN EGGPLANT <span>$8.95</span></h3>
              <p>Deep fried Eggplant batted with egg, flour served with sweet & spicy sauce.</p>
              <h3>11. FRESH VEGETABLE SPRING ROLLS <span>$8.95</span></h3>
              <p>Steamed rice wrappers filled with rice noodles Shrimps or Vegetables, with mint and basil. (WITH SHRIMP $8.95)</p>
              <h3>11A. SHRIMP ROLLS <span>$11.95</span></h3>
              <p>Deep fried shrimp rolls served with sweet and sour sauce.</p>


             
            </div>
            <div className="col-12 col-md-6">
              <h2>Soup</h2>
              <h3>12. TOM YUM KUNG (shrimp) <span>$13.95 / $15.95</span></h3>
              <h3>13. TOM YUM POA TAK (seafood) <span>$13.95 / $16.95</span></h3>
              <h3>14. TOM YUM KAI (chicken) <span>$11.95 / $12.95</span></h3>
              <h3>15. TOM YUM PUCK (vegetables) <span>$9.95 / $13.95</span></h3>
              <p>Choice of Meat or Assorted vegetables mushrooms and cilantro simmered in hot and sour flavored broth with lime juice, lemongrass, and crushed of roasted pepper.</p>
              <h3>16. TOM KHA KUNG (shrimp) <span>$13.95 / $16.95</span></h3>
              <h3>17. TOM KHA KAI (chicken) <span>$11.95 / $14.95</span></h3>
              <h3>18. TOM KHA PUCK (vegetables) <span>$10.95 / $14.95</span></h3>
              <p>Choice of Meat or Assorted Vegetables simmered in coconut milk broth with lime juice, lemongrass, galanga, and cilantro.</p>

              <h3>19. KANG JUDE WOON SEN <span>$13.95 / $15.95</span></h3>
              <p>Prawns, chicken, and Napa cabbage, glass noodle simmered in garlic soup and green onion.</p>
              <h3>20. SIAM RICE SOUP <span>$9.95 / $13.95</span></h3>
              <p>Chicken & Shrimp and rice simmered in garlic, chicken broth soup, ginger, onion</p>

              <h2>Salad</h2>
<h3>21. YUM NUR <span>$12.95</span></h3>
<p>Sliced beef steak with lime juice, chili, garlic, cucumber, lemongrass, fish sauce, onion.</p>
<h3>22. NUR NAM TOK <span>$12.95</span></h3>
<p>Grilled beef steak with chili, toasted rice powder, chili oil sauce bedded with lettuce.</p>
<h3>23. THAI SALAD WITH CHICKEN <span>$9.95</span></h3>
<p>Assorted vegetables and chicken topped with our delicious peanut curry sauce. (WITH VEGETABLES $7.95)</p>
<h3>24. ROASTED EGGPLANT SALAD <span>$9.50</span></h3>
<p>Roasted eggplant salad, with lime juice, garlic, chili, and red onion.</p>
<h3>25. CALAMARI SALAD <span>$12.95</span></h3>
<p>Calamari salad, seasoned with lime juice, chili sauce, garlic, onions, ginger, lettuce, cilantro.</p>
<h3>26. LARB DUCK <span>$13.95</span></h3>
<p><h3>27 LARB BEEF / CHICKEN / PORK <span>$11.50</span></h3></p>
<p>Seasoned Duck or Ground Beef or Ground Chicken or Ground Pork or Ground Tofu, with lime juice, roasted chili sauce, rice- powder, mint leaves, peanuts. (WITH TOFU $9.50)</p>
<h3>28. PLA KUNG <span>$14.95</span></h3>
<p>Prawn salad seasoned with lime juice, pepper, cilantro, onion, and lettuce.</p>
<h3>29. GREEN PAPAYA SALAD <span>$10.95</span></h3>
<p>Shredded green papaya, marinated with prawns, tomatoes, green bean, peanuts, chili & lime juice.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="padthai-bg"></div>
        <div className="container menu">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2>Poultry</h2>
<h3>30. CHICKEN WITH GARLIC PEPPER <span>$12.95</span></h3>
<p>Chicken sautéed with fresh garlic, mushrooms, broccoli, Thai herbs, cilantro.</p>
<h3>31. CHICKEN WITH THAI BASIL <span>$12.95</span></h3>
<p>Sliced chicken breast sautéed with chili, garlic, sweet onion, and Thai basil.</p>
<h3>32. RED CURRY CHICKEN <span>$12.95</span></h3>
<p>Chicken simmered in coconut milk, bamboo shoots, sweet basil, and red curry.</p>
<h3>33. GREEN CURRY CHICKEN <span>$12.95</span></h3>
<p>Chicken simmered in green curry, coconut milk, eggplant, Thai basil, and bell pepper.</p>
<h3>34. PANANG CHICKEN CURRY <span>$12.95</span></h3>
<p>Chicken simmered in Panang curry, coconut milk, peas and bell pepper.</p>
<h3>35. YELLOW CURRY CHICKEN <span>$12.95</span></h3>
<p>Chunks of chicken simmered in coconut milk, potatoes in yellow curry, and onion.</p>
<h3>36. THAI BBQ CHICKEN <span>$12.95</span></h3>
<p>Boneless chicken marinated in honey and Thai herbs served with sweet chili sauce.</p>
<h3>37. RAMA'S CHICKEN <span>$12.95</span></h3>
<p>Marinated chicken with steamed mushroom spinach topped with peanut curry sauce.</p>
<h3>38. IMPERIAL CHICKEN <span>$12.95</span></h3>
<p>Chicken sautéed with broccoli, oyster sauce.</p>
<h3>39. THAI STYLE SWEET & SOUR CHICKEN <span>$12.95</span></h3>
<p>Sweet and sour chicken with cucumbers, onion, tomatoes, bell pepper, and pineapple.</p>
<h3>40. RED CURRY DUCK <span>$14.95</span></h3>
<p>Roasted duck curry; simmered in red curry, tomatoes, eggplant, pineapple, sweet basil.</p>
<h3>41. CASHEW CHICKEN <span>$12.95</span></h3>
<p>Chicken sautéed with cashew nuts, onions mushroom, bell pepper, roasted peppers.</p>
<h3>42. MANGO CHICKEN & CASHEWS <span>$13.95</span></h3>
<p>Chicken sautéed with fresh mango, cashew nuts, bell pepper, onions</p>
            </div>
            <div className="col-12 col-md-6">
              <h2>Pork</h2>
<h3>43. PORK WITH HOT PEPPERS <span>$12.95</span></h3>
<p>Sliced pork sautéed with chili peppers, sweet onion, garlic, green onion, and oyster sauce.</p>
<h3>44. PORK WITH THAI BASIL <span>$12.95</span></h3>
<p>Pork sautéed with chili, fresh garlic, sweet onion and Thai basil.</p>
<h3>45. MOO PRIG KHING <span>$12.95</span></h3>
<p>Pork sautéed with red curry, green beans, kiffer-lime leaves, Thai spices.</p>
<h3>46. MOO PAD PED <span>$12.95</span></h3>
<p>Pork sautéed with eggplant, mushroom, bell pepper,coconut milk, red curry, Kaffir leaves, and Thai basil.</p>
<h3>47. PORK WITH GARLIC <span>$12.95</span></h3>
<p>Pork sautéed with fresh garlic, broccoli, mushroom, Thai herbs, black pepper, and cilantro.</p>
<h3>48. BBQ PORK <span>$12.95</span></h3>
<p>Served with homemade sweet chili sauce.</p>
<h3>49. THAI STYLE SWEET & SOUR PORK <span>$12.95</span></h3>
<p>Sweet & sour pork with cucumbers, onion, tomatoes, bell pepper and pineapple.</p>
<h3>50. GINGER PORK <span>$12.95</span></h3>
<p>Sautéed with fresh mushroom, ginger, and sweet onion.</p>
<h3>100.CRISPY PORK & CHINESE BROCCOLI <span>$14.95</span></h3>
<p>Stirred-fried crispy pork and Chinese broccoli with garlic and oyster sauce.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="curry-bg" />
        <div className="container menu">
          <div className="row">
            
            <div className="col-12 col-md-6">
              <h2>Beef</h2>
<h3>51. BEEF WITH THAI BASIL <span>$14.95</span></h3>
<p>Beef sautéed with chili, garlic, sweet onion, and Thai basil.</p>
<h3>52. PANANG NUR <span>$14.95</span></h3>
<p>Beef simmered in Panang curry, coconut milk, peas, and bell peppers.</p>
<h3>53. MUS-A-MAN <span>$14.95</span></h3>
<p>Beef stew curry with coconut milk, potato, tomato and red onion.</p>
<h3>54. KANG PAH <span>$14.95</span></h3>
<p>Sliced beef sautéed with red curry, bamboo shoots, mushroom, green bean, peppers.</p>
<h3>55. RED CURRY BEEF & PUMPKIN <span>$14.95</span></h3>
<p>Beef in red curry with coconut milk, pumpkin, peppers, and basil.</p>
<h3>56. IMPERIAL BEEF <span>$14.95</span></h3>
<p>Beef sautéed with broccoli crown and oyster sauce.</p>
<h3>57. BEEF WITH BAMBOO SHOOTS <span>$14.95</span></h3>
<p>Beef sautéed with fresh garlic, pepper, bamboo shoots, and Thai basil.</p>
<h3>58. BBQ BEEF <span>$14.95</span></h3>
<p>Grilled beef with homemade seasoning.</p>
<h3>59. THAI LEMONGRASS BEEF <span>$14.95</span></h3>
<p>Beef and green bean stir fried with Thai spices, mushroom, and lemongrass.</p>
            </div>
            <div className="col-12 col-md-6">
              <h2>Seafood</h2>
<h3>60. PRAWN CHU CHEE <span>$15.95</span></h3>
<p>Prawns simmered in Panang curry, coconut milk, peas, bell pepper, and sweet basil.</p>
<h3>61. PRAWNS WITH BASIL <span>$15.95</span></h3>
<p>Prawns sautéed with garlic, sweet onion, peppers, and Thai basil.</p>
<h3>62. RED CURRY PRAWNS <span>$15.95</span></h3>
<p>Red curry prawns simmered in coconut milk with pineapple and basil leaves.</p>
<h3>63. PRAWNS WITH GARLIC PEPPER <span>$15.95</span></h3>
<p>Sautéed with fresh garlic, black pepper, Thai spices, and cilantro.</p>
<h3>64. PRINCESS'S FAVORITE PRAWNS <span>$15.95</span></h3>
<p>Sweet and sour prawns with cucumbers, onion, tomatoes, bell peppers, pineapple.</p>
<h3>65. IMPERIAL PRAWNS <span>$15.95</span></h3>
<p>Prawns sautéed with broccoli, mushroom, and oyster sauce.</p>
<h3>66. PLA PEW WAN <span>$15.95</span></h3>
<p>Deep fried Soles topped with onion, bell pepper, mushroom, and homemade chili garlic sauce.</p>
<h3>67. PLA LARD PRIG <span>$15.95</span></h3>
<p>Deep fried Soles topped with onion, bell pepper, mushroom, and homemade chili garlic sauce.</p>
<h3>68. PANANG FISH <span>$15.95</span></h3>
<p>Fillets of sole fish sautéed in Panang curry sauce, coconut milk, bell peppers, peas, and Kaffir-lime leaves.</p>
<h3>69. PLA PAD PED <span>$15.95</span></h3>
<p>Deep fried boneless sole fish sautéed with red curry, garlic, chili, and topped with crispy sweet basil.</p>
<h3>70. CALAMARIES WITH THAI BASIL <span>$14.95</span></h3>
<p>Calamari fillets sautéed with chili, garlic, sweet onion, and Thai basil.</p>
<h3>71. CALAMARIES WITH GARLIC <span>$14.95</span></h3>
<p>Calamari fillets sautéed with fresh garlic, black pepper, and Thai herb.</p>
<h3>72. CHICKEN WING BASIL <span>$14.95</span></h3>
<p>Deep fried wings with garlic, chili paste, onions, bell pepper, and basil.</p>
<h3>73. SEAFOOD WITH THAI BASIL <span>$16.95</span></h3>
<p>Prawns, calamari, scallop, and green mussels sauteed with chili, garlic, sweet onion, peppers, and Thai basil.</p>
<h3>101.PAD CHAR THAI VILLAGE <span>$17.95</span></h3>
<p>Sizzling seafood with garlic, Thai chili paste, bell pepper, and basil.</p>
<h3>102.LEMON FISH <span>$15.95</span></h3>
<p>Steam filleted with lime juice and Thai chili.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="drink-bg" />
        <div className="container menu">
          <div className="row">
            
            <div className="col-12 col-md-6">
              <h2>Fried Rice</h2>
<h3>74. DUCK FRIED RICE <span>$14.95</span></h3>
<h3>75. CRAB MEAT & SHRIMP FRIED RICE <span>$14.95</span></h3>
<h3>76. GREEN CURRY CHICKEN & SHRIMP FRIED RICE <span>$14.95</span></h3>
<h3>77. SPICY BASIL FRIED RICE WITH CHICKEN <span>$12.95</span></h3>
<p>Thai jasmine rice pan-fried with Chicken or Vegetable, Thai basil, and chili pepper. (WITH VEGETABLES $10.50)</p>
<h3>78. THAI HOUSE FRIED RICE WITH CHICKEN / PORK / BEEF <span>$12.95</span></h3>
<p>Thai style fried rice with Choice of Meat or Vegetables. (WITH VEGETABLES $11.95)</p>
<h3>79. PRAWN FRIED RICE <span>$13.95</span></h3>
<h3>80. PINEAPPLE FRIED RICE WITH CHICKEN & SHRIMP <span>$14.95</span></h3>
<p>Thai jasmine rice pan-fried with cashew nuts, pineapple, and Thai Spices.</p>
<h3>80A.CRISPY CHICKEN FRIED RICE <span>$14.95</span></h3>
<p>Thai style fried rice on top with crispy chicken.</p>
<h3>103.CHINESE SAUSAGE FRIED RICE <span>$13.95</span></h3>
<p>Fried rice with Chinese broccoli and sausage, egg, onion and green onion.</p>
            </div>

            <div className="col-12 col-md-6">
              <h2>Noodle</h2>
<h3>81. PAD THAI</h3>
<h3>Vegetables <span>$11.95 / Tofu $11.95</span></h3>
<h3>Chicken <span>$12.95 / Pork $12.95</span></h3>
<h3>Beef <span>$13.95 / Shrimp $13.95</span></h3>
<p>Rice noodles pan fried with broccoli, chili powder, egg, green onions, bean sprouts and peanuts.</p>
<h3>82. PAD THAI SHRIMP <span>$13.95</span></h3>
<h3>83. HOUSE NOODLES <span>$12.95</span></h3>
<p>Flat rice noodles pan-fried with chicken, green onion, bedded with lettuce.</p>
<h3>84. PAD KEE MAO</h3>
<h3>Vegetables <span>$11.95 / Tofu $11.95</span></h3>
<h3>Chicken <span>$12.95 / Pork $12.95</span></h3>
<h3>Beef <span>$13.95 / Shrimp $13.95</span></h3>
<h3>Mixed Seafood <span>$14.95</span></h3>
<p>Flat rice noodles pan-fried with Thai basil and chili pepper and vegetables.</p>
<h3>85. PAD SEE EW</h3>
<h3>Vegetables <span>$11.95 / Tofu $11.95</span></h3>
<h3>Chicken <span>$12.95 / Pork $12.95</span></h3>
<h3>Beef <span>$13.95 / Shrimp $13.95</span></h3>
<h3>Mixed Seafood <span>$14.95</span></h3>
<p>Flat rice noodles pan-fried with broccoli, egg and vegetables.</p>
<h3>86. PAD WOON SEN <span>$14.95</span></h3>
<p>Bean thread silver noodles, pan fried with chicken, prawns, egg, bean sprouts, and green onion.</p>
<h3>87. THAI BEEF NOODLES SOUP <span>$12.95</span></h3>
<p>Rice noodles with sliced beef, stew broth soup, beef balls, bean sprout, cilantro, and green onion.</p>
<h3>N1. JUN PAD PU <span>$13.95</span></h3>
<p>Rice noodles pan-fried with Crab meat, chili pepper, egg, lemon juice, bean sprout and green onion.</p>
<h3>N2. RAD NA NOODLES</h3>
<h3>Vegetables <span>$12.95 / Tofu $12.95</span></h3>
<h3>Chicken <span>$12.95 / Pork $12.95</span></h3>
<h3>Beef <span>$13.95 / Shrimp $13.95</span></h3>
<p>Flat rice noodles, pan-fried with gravy sauce and Chinese broccoli.</p>
<h3>N3. TOM YUM NOODLE SOUP <span>$13.95</span></h3>
<p>Rice noodles, ground pork shrimps and squid with hot and sour flavored broth, lime juice, lemongrass, cilantro and peanuts.</p>
<h3>N4. THAI FRIED RICE WITH CRISPY CHICKEN <span>$14.95</span></h3>
<h3>N5. CHOW MEIN WITH CRISPY CHICKEN <span>$14.95</span></h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="lunch-bg" />
        <div className="container menu">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2>Vegetables</h2>
<h3>88. PANANG TOFU CURRY <span>$11.95</span></h3>
<p>Crispy tofu simmered in Panang curry, coconut milk, and green peas.</p>
<h3>89. VEGETABLE DELIGHT <span>$11.95</span></h3>
<p>Sautéed mixed vegetables with garlic sauce.</p>
<h3>90. RED CURRY WITH VEGETABLES <span>$11.95</span></h3>
<p>Red curry, assorted vegetables simmered in red curry sauce and coconut milk.</p>
<h3>91. GREEN CURRY WITH VEGETABLES <span>$11.95</span></h3>
<p>Green curry, assorted vegetables simmered in green curry and coconut milk.</p>
<h3>92. VEGETABLES & TOFU WITH THAI BASIL <span>$11.95</span></h3>
<p>Mixed vegetables and tofu sautéed with chili pepper, garlic and Thai basil.</p>
<h3>93. FIRE SPINACH CHILI BEANSPROUT <span>$12.95</span></h3>
<p><h3>94. EGGPLANT IN SPICY SAUCE <span>$11.95</span></h3></p>
<p>Eggplant sautéed in garlic oil, pepper, onion, mushroom, and Thai basil.</p>
<h3>95. FIRE BROCCOLI <span>$11.95</span></h3>
<p>Crowns of broccoli sautéed in oyster sauce.</p>
<h3>96. STIR FRIED MANGO & TOFU <span>$11.95</span></h3>
<p>Fresh Mango, assorted vegetables and fried tofu sautéed with chili pepper, garlic and cashew.</p>
<h3>97. VEGETABLES & TOFU IN GARLIC PEPPER <span>$11.95</span></h3>
<p><h3>98. YELLOW CURRY TOFU <span>$11.95</span></h3></p>
<p><h3>99. FIRE GREEN BEANS <span>$11.95</span></h3></p>
<p>Sautéed green bean with garlic sauce.</p>
            </div>
            <div className="col-12 col-md-6">
              <h2>Side Orders</h2>
<h3>STEAMED JASMINE WHITE RICE <span>$2.00</span></h3>
<h3>STEAMED JASMINE BROWN RICE <span>$2.50</span></h3>
<h3>STICKY RICE <span>$3.00</span></h3>
<h3>COCONUT RICE <span>$3.00</span></h3>
<h3>PEANUT SAUCE <span>$1.50</span></h3>
<h3>CUCUMBER SALAD <span>$3.25</span></h3>
<h3>COMPLETE DINNER SET <span>$4.75</span></h3>
<p>Served with soup of the day, Thai salad, steamed rice, choice of one entrée, and a hot tea.</p>
<h2>Desserts</h2>
<h3>FRIED BANANA WITH<br />HOMEMADE COCONUT ICE CREAM <span>$6.95</span></h3>
<br />
<h3>SWEET STICKY RICE WITH FRESH MANGO <span>$7.95</span></h3>
<h3>THAI CUSTARD ON<br />SWEET RICE & COCONUT MILK <span>$6.95</span></h3>
<br />
<h3>HOMEMADE COCONUT ICE CREAM <span>$3.75</span></h3>
<h3>GREEN TEA ICE CREAM <span>$3.75</span></h3>
<h3>VANILLA ICE CREAM <span>$3.75</span></h3>
<br />
<h3>TAPIOCA PUDDING & TARO IN<br />SWEET COCONUT MILK <span>$4.25</span></h3>
<br />
<h3>SWEET ROTI BREAD AND CONDENSED MILK <span>$4.50</span></h3>
<h2>Beverages</h2>
<h3>THAI ICED TEA <span>$3.75</span></h3>
<h3>THAI ICED COFFEE <span>$3.75</span></h3>
<h3>MANGO JUICE <span>$3.50</span></h3>
<h3>FRESH YOUNG COCONUT JUICE <span>$4.95</span></h3>
<h3>SODA <span>$2.00</span></h3>
<p>(coke, diet coke, sprite, root beer, or orange soda)</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default MenuPage
